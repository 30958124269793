<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import {useCurrentUser} from "~/composables/states";

// const route = useRoute()
useHead({
  titleTemplate: '%s - Q-SMS',
})

const user = useCurrentUser()
window.fetch('/api/me')
  .then(async res => {
    if (!res.ok) {
      // We are not authenticated, so let's return to the login page
      const resp = await res.text()
      console.error(resp)
      window.location.href = '/auth/?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search)
      throw new Error("login required: " + resp)
    }

    return res.json()
  }).then(loggedInUser => {
    user.value.userId = loggedInUser.userId
    user.value.email = loggedInUser.email
    user.value.name = loggedInUser.name
    user.value.userType = loggedInUser.type
    user.value.org = loggedInUser.org
    console.debug("logged in as", user)
  })

</script>
